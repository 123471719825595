import React from 'react'
import { useStyle } from '../../hooks/classify'
import { StaticImage } from 'gatsby-plugin-image'
import * as defaultClasses from './iconSet.module.css'

const IconSet = ({ title = 'Hello', text = 'How are you?', parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)

    // const titleClass = classes.title ? classes.title : ''
    // const titleClassCombined = `hOne ${titleClass}`

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                {/* <div className={classes.contentWrapper}>
                    <h3 className={titleClassCombined}>{title}</h3>
                    <p className={classes.text}>{text}</p>
                </div> */}
                <div className={classes.iconWrapper}>
                    <div>
                        <StaticImage
                            src="../../images/ilm-logo.png"
                            alt={title}
                            className={classes.image}
                        />
                    </div>
                    <div>
                        <StaticImage
                            src="../../images/emcc-logo.jpg"
                            alt={title}
                            className={classes.image}
                        />
                    </div>
                    <div>
                        <StaticImage
                            src="../../images/cipd-logo.png"
                            alt={title}
                            className={classes.image}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IconSet
