import React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => {
    return (
        <>
            <div className="container mx-auto my-6 text-center">
                <h3>Sorry 😔, we couldn't find what you were looking for.</h3>
                <br />
                <br />
                <Link to="/">Go home</Link>.
            </div>
        </>
    )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
