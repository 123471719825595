import './src/styles/global.css'

import React from 'react'
import Layout from './src/components/layout'
import LayoutProvider from './src/providers/layoutProvider'

export const wrapRootElement = ({ element }) => {
    return <LayoutProvider>{element}</LayoutProvider>
}
export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}
