import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './listOne.module.css'

const ListOne = ({
    title = 'Hello',
    text = 'How are you?',
    listOneData,
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const list = listOneData.map((ld, index) => (
        <div role="listitem" key={index} className={classes.listItem}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 122.88 92.81"
                className={classes.svg}
            >
                <path d="M106.97,92.81H84.89c-8.5,0-15.45-6.95-15.45-15.45c0-31.79-8.12-66.71,30.84-76.68 c17.65-4.51,22.25,14.93,3.48,16.27c-11.45,0.82-13.69,8.22-14.04,19.4h17.71c8.5,0,15.45,6.95,15.45,15.45v25.09 C122.88,85.65,115.72,92.81,106.97,92.81L106.97,92.81z M38.23,92.81H16.15c-8.5,0-15.45-6.95-15.45-15.45 c0-31.79-8.12-66.71,30.84-76.68C49.2-3.84,53.8,15.6,35.02,16.95c-11.45,0.82-13.69,8.22-14.04,19.4H38.7 c8.5,0,15.45,6.95,15.45,15.45v25.09C54.14,85.65,46.98,92.81,38.23,92.81L38.23,92.81z"></path>
            </svg>
            <p className={classes.listItemText}>{ld.text}</p>
        </div>
    ))

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `formalTitle ${titleClass}`
    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <div className={classes.contentWrapper}>
                    <h3 className={titleClassCombined}>{title}</h3>
                    {/* <p className={classes.text}>{text}</p> */}
                    <div className={classes.divider} />
                </div>
                <div role="list" className={classes.listWrapper}>
                    {list}
                </div>
            </div>
        </div>
    )
}

export default ListOne
