import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './testimonialSlider.module.css'
import testimonialsData from './testimonialsData'
import Slider from '../slider'

const TestimonialSlider = ({ parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)
    const testimonials = testimonialsData.map((testimonial, index) => {
        return (
            <div className={classes.slideContainer} key={index}>
                <div className={classes.testimonialWrapper}>
                    <p className={classes.testimonial}>
                        {testimonial?.content}
                    </p>
                </div>
                <div className={classes.detailsWrapper}>
                    <div className={classes.details}>
                        <h3 className={classes.name}>{testimonial?.name}</h3>
                        <h4 className={classes.industy}>
                            {testimonial?.title}
                        </h4>
                        <div className={classes.divider2} />
                    </div>
                </div>
            </div>
        )
    })

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `hOne ${titleClass}`

    return (
        <div className={classes.container} id="testimonials">
            <div className={classes.wrapper}>
                <div className={classes.titleWrapper}>
                    <h3 className={titleClassCombined}>Testimonials</h3>
                    <div className={classes.divider1} />
                </div>

                <div className={classes.sliderWrapper}>
                    <Slider
                        effect="fade"
                        loop={true}
                        navigation={true}
                        pagination={true}
                        autoplay={7000}
                        spaceBetween={30}
                    >
                        {testimonials}
                    </Slider>
                </div>
            </div>
        </div>
    )
}
export default TestimonialSlider
