import React from 'react'
import { Seo } from '../components/seo'
import Button from '../components/button/button'
import FwImgTitleText from '../components/fwImgTitleText'
import PageTitle from '../components/pageTitle'
import ListTwo from '../components/listTwo'
import Spacer from '../components/spacer'
import Cta from '../components/cta'
import ContentBlock from '../components/contentBlock'

const HRPage = () => {
    const listTwoData = [
        {
            title: 'HR Strategy, ensuring strategy is embedded in organisational goals',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Culture and values',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Performance management',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Recruitment and onboarding',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Employee Relations, including grievances and disciplinaries',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Change management',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
    ]

    return (
        <>
            <PageTitle
                title="HR Expertise"
                text="As an experienced HR Specialist, I am commercially-minded, pragmatic and professional. This expertise complements my training and experience in executive coaching."
            />
            <Spacer />
            <FwImgTitleText
                image="hr"
                title="My mantra is 'make a difference'"
                text="This mantra and going the extra degree is reflected in the work I carry out with, and for, clients."
            />
            <Spacer />
            <ListTwo
                title="HR Services"
                text="My background spans a broad range of industries and requiring HR agility and commercial focus. I support and clients in a range of HR-related topics, such as:"
                listTwoData={listTwoData}
            />

            <Cta text="By providing us with thoughtful and balanced HR advice and support, Charlie enabled us to manage what was a difficult and sensitive situation brilliantly. Her knowledge, professionalism and expertise is second to none so if you are looking for a strategic HR specialist to quickly assess a complex situation and advise on the right direction to take, I strongly recommend you use Charlie." />

            <ContentBlock>
                <p>
                    My experience as an HR Specialist in a wide range of
                    industries equip me with a commercial and pragmatic mindset;
                    this added-value of having operated as a business
                    professional enhances clients' Return on Investment and
                    empowers them to realise their goals.{' '}
                </p>
                <p>
                    Our sessions will be led by you, with you, as client,
                    bringing the topics for discussion. I abide by strict
                    confidentiality principles and, during the course of our
                    sessions, ensure you have the time and space to clarify your
                    intentions, explore opportunities and achieve your goals.{' '}
                </p>
                <p>
                    After an initial enquiry, I offer an informal and
                    complimentary 30 minute 'virtual coffee' session to meet and
                    understand your needs. the needs of you as a client and how
                    I can with you to achieve your goals. I don't believe in
                    offering structured 'coaching packages' as there is no one
                    size fits all. Instead, I advise at least 6 sessions in
                    order to maximise the effectiveness of coaching. each
                    session is charged individually, with a recommendation that
                    for the effectiveness of coaching to be maximised, a minimum
                    of 6 sessions is advisable. In addition, I am available free
                    of charge by phone, text or email between sessions as a temp
                    check and to enable us to touch base with any burning
                    matters or questions.
                </p>
                <p>
                    Contact me for a free informal discussion about how I can
                    add value to your organisation and your people.
                </p>
                <div className="mt-ms4 w-full text-center">
                    <Button
                        url="/contact"
                        text="Book a free consultation"
                        variation="primary"
                    />
                </div>
            </ContentBlock>
        </>
    )
}

export default HRPage

export const Head = ({ location }) => {
    return (
        <Seo
            title="HR Expertise"
            description="As an experienced HR Specialist, I am commercially-minded, pragmatic and professional. This expertise complements my training and experience in executive coaching."
            pathname={location?.pathname}
        />
    )
}
