import React from 'react'
import downloadFile from '../downloads/privacy-policy-2023.pdf'

const PrivacyPage = () => {
    return (
        <div className="container mx-auto">
            <h1 className="hOne my-ms4">Website Privacy Policy</h1>
            <p>
                361 People takes seriously its duty to protect your personal
                data and privacy. The website Privacy Policy outlines how your
                personal data is handled, as well as your privacy rights.{' '}
            </p>
            <a
                href={downloadFile}
                download
                className="block mt-ms2 underline decoration-from-font transition ease-in-out hover_text-secondary"
            >
                Download Privacy Policy
            </a>
        </div>
    )
}

export default PrivacyPage
