import React from 'react'
import { useStyle } from '../../hooks/classify'
import { StaticImage } from 'gatsby-plugin-image'
import * as defaultClasses from './heroOne.module.css'

const HeroOne = ({
    image = 'default',
    title = 'Hello',
    text = 'How are you?',
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    function imageSelector() {
        if (image === 'default') {
            return (
                <StaticImage
                    src="../../images/placeholder.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        } else if (image === 'about') {
            return (
                <StaticImage
                    src="../../images/Charlie-V-150.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        }
    }

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `hOne ${titleClass}`

    return (
        <>
            <div className={classes.container}>
                <div className={classes.imageWrapper}>{imageSelector()}</div>
                <div className={classes.contentContainer}>
                    <div className={classes.contentWrapper}>
                        <h1 className={titleClassCombined}>{title}</h1>
                        <p className={classes.text}>{text}</p>
                    </div>
                </div>
            </div>
            <div className={classes.bkShape} />
        </>
    )
}

export default HeroOne
