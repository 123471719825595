import React from 'react'
import { useStyle } from '../../hooks/classify'
import { Link } from 'gatsby'

const MenuItems = ({ parentClasses }) => {
    const classes = useStyle(parentClasses)
    return (
        <ul className={classes.linksWrapper}>
            <li className={classes.link}>
                <Link to="/" title="Home">
                    Home
                </Link>
            </li>
            <li className={classes.link}>
                <Link to="/about" title="About me">
                    About me
                </Link>
            </li>
            <li className={classes.link}>
                <Link to="/executive-coaching" title="Executive Coaching">
                    Executive Coaching
                </Link>
            </li>
            <li className={classes.link}>
                <Link to="/hr-expertise" title="HR Expertise">
                    HR Expertise
                </Link>
            </li>
            <li className={classes.link}>
                <Link to="/contact" title="Contact me">
                    Contact me
                </Link>
            </li>
        </ul>
    )
}

export default MenuItems
