const data = [
    {
        content:
            'Charlie was very skilled in questioning in order to promote discussion and prompt other lines of thinking. She did not presume any one answer was correct and did not interject with her personal opinions. Discussions were guided using her professional expertise and thought-provoking questions.',
        name: 'WE',
        title: 'Headteacher',
    },
    {
        content:
            "Charlie has a wonderful way of making you voice your thoughts and in doing so, you realise what you’re trying to achieve. This insight was fantastic, as it clarified what I needed to focus on and prioritise.  Charlie offered up observations, where appropriate and I never felt 'judged'.",
        name: 'JY, CEO',
        title: 'Homeware and Fashion brand',
    },
    {
        content:
            'Charlie was so good at asking me insightful questions that really made me think between sessions.  Charlie was great at challenge, helping me see issues from a bigger perspective, or joining the dots between issues.  I have really valued my time working with Charlie, and expect I will reflect that this year has been a pivot point for me in many ways and Charlie has been a big part of that journey.',
        name: 'HW, Finance Lead',
        title: 'Oil & Gas',
    },
    {
        content:
            'By providing us with thoughtful and balanced HR advice, Charlie enabled us to manage what was a difficult and sensitive situation brilliantly. Her knowledge, professionalism and expertise is second to none so if you are looking for a strategic HR specialist to quickly assess a complex situation and advise on the right direction to take, I strongly recommend you use Charlie.',
        name: 'JW',
        title: 'Business Consultancy ',
    },
]

export default data
