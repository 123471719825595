import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './listTwo.module.css'

const ListTwo = ({
    title = 'Hello',
    text = 'How are you?',
    listTwoData,
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const list = listTwoData.map((ld, index) => (
        <div role="listitem" key={index} className={classes.listItem}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 512 512"
                className={classes.svg}
            >
                <polygon points="256 12.53 335.11 172.82 512 198.53 384 323.29 414.21 499.47 256 416.29 97.78 499.47 128 323.29 0 198.53 176.89 172.82 256 12.53" />
            </svg>
            <h3 className={classes.listItemTitle}>{ld.title}</h3>
            {/* <p className={classes.listItemText}>{ld.text}</p> */}
        </div>
    ))

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `hOne ${titleClass}`
    return (
        <div className={classes.container}>
            <div className={classes.contentWrapper}>
                <h3 className={titleClassCombined}>{title}</h3>
                <p className={classes.text}>{text}</p>
            </div>
            <div className={classes.listContainer}>
                <div role="list" className={classes.listWrapper}>
                    {list}
                </div>
            </div>
        </div>
    )
}

export default ListTwo
