import React from 'react'
import { useStyle } from '../../hooks/classify'
import { StaticImage } from 'gatsby-plugin-image'
import * as defaultClasses from './heroTwo.module.css'

const HeroTwo = ({
    image = 'default',
    title = 'Hello',
    children,
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    function imageSelector() {
        if (image === 'default') {
            return (
                <StaticImage
                    src="../../images/placeholder.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        } else if (image === 'index') {
            return (
                <StaticImage
                    src="../../images/Charlie-V-155.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        } else if (image === 'about') {
            return (
                <StaticImage
                    src="../../images/Charlie-V-154.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        }
    }

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `hOne ${titleClass}`

    return (
        <>
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <div className={classes.contentWrapper}>
                        <h1 className={titleClassCombined}>{title}</h1>
                        {children && (
                            <div className={classes.buttonWrapper}>
                                {children}
                            </div>
                        )}
                    </div>
                    <div className={classes.imageWrapper}>
                        {imageSelector()}
                    </div>
                </div>
            </div>
            <div className={classes.bkShape} />
        </>
    )
}

export default HeroTwo
