import React from 'react'
import { useStyle } from '../../hooks/classify'
import { StaticImage } from 'gatsby-plugin-image'
import * as defaultClasses from './fwImgTitleText.module.css'
const FwImgTitleText = ({
    image = 'default',
    title = 'Welcome to my website',
    text = 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    function imageSelector() {
        if (image === 'default') {
            return (
                <StaticImage
                    src="../../images/placeholder.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        } else if (image === 'about') {
            return (
                <StaticImage
                    src="../../images/Charlie-V-142.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        } else if (image === 'exec') {
            return (
                <StaticImage
                    src="../../images/ideas.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        } else if (image === 'hr') {
            return (
                <StaticImage
                    src="../../images/hand-shake.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        }
    }

    return (
        <div className={classes.wrapper}>
            {imageSelector()}
            <div className={classes.contentWrapper}>
                <h3 className={classes.title}>{title}</h3>
                <p className={classes.text}>{text}</p>
            </div>
        </div>
    )
}
export default FwImgTitleText
