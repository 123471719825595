import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './contentBlock.module.css'

const ContentBlock = ({ children, parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)
    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <div className={classes.text}>{children}</div>
            </div>
        </div>
    )
}

export default ContentBlock
