import React from 'react'
import { Seo } from '../components/seo'
import { useSiteMetadata } from '../hooks/useSiteMetadata'
import TextImgText from '../components/textImgText'
import HeroTwo from '../components/heroTwo'
import IconSet from '../components/iconSet'
import ListOne from '../components/listOne'
import Button from '../components/button/button'
import Cta from '../components/cta'
import Spacer from '../components/spacer'

const IndexPage = () => {
    const listOneData = [
        {
            text: 'After each session, I felt more positive and more confident about tackling challenges',
        },
        {
            text: 'She is a perceptive questioner, an active listener and draws insights out of you that until she asked the right question, you never realised were there',
        },
        {
            text: 'My sessions with Charlie have been transformational, both on myself in my role and in facilitating a deeper insight into myself as a leader',
        },
    ]

    return (
        <>
            <HeroTwo
                image="index"
                title="Executive Coach, Business Coach and HR Specialist, supporting clients in Cornwall, South West England and beyond."
            />
            <div className="pt-ms6" />
            <TextImgText
                image="index"
                title="Hello, I'm Charlie."
                text="<p>I'm a client-focused, commercial and insightful Executive Coach  and HR Specialist.  I empower people to realise their goals.  Recognising that all clients have their own distinct and individual challenges and opportunities, I offer a bespoke service to reflect my clients' needs.</p>
                <p>I work with business leaders, managers, entrepreneurs, teams and businesses to support them in fulfilling their potential in line with their own values, preferences and styles, as well as delivering an excellent ROI. </p>"
                title2="At 361 People, I help clients go the extra degree."
                text2="<p>Take a look at what my clients say about working with me, and contact me for a free informal, investigatory chat to find out more.</p>"
            />
            <div className="text-center mt-ms6 flex justify-center">
                <Button
                    url="/about#testimonials"
                    text="Read my testimonials"
                    variation="secondary"
                />
                <Button
                    url="/contact"
                    text="Book a free consultation"
                    variation="primary"
                />
            </div>
            <Spacer />
            <Cta
                parentClasses={{
                    container: 'w-full py-ms8 bg-tertiary',
                    title: 'text-secondary',
                }}
                text="You can't depend on your eyes when your imagination is out of focus"
            >
                Mark Twain
            </Cta>
            <Spacer />
            <ListOne
                title="What my clients say"
                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                listOneData={listOneData}
            />
            <Spacer />
            <IconSet
                title="Accreditations"
                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
            />
        </>
    )
}

export default IndexPage

export const Head = ({ location }) => {
    const {
        title,
        description,
        type,
        image,
        siteUrl,
        phone,
        email,
        streetAddress,
        addressLocality,
        addressRegion,
        postalCode,
    } = useSiteMetadata()
    const structuredData = {
        '@context': 'https://schema.org',
        '@type': type,
        name: title,
        description: description,
        image: image,
        logo: image,
        url: siteUrl,
        telephone: phone,
        contactPoint: [
            {
                '@type': 'ContactPoint',
                contactType: 'Customer service',
                telephone: phone,
                email: email,
            },
        ],
        address: {
            '@type': 'PostalAddress',
            streetAddress: streetAddress,
            addressLocality: addressLocality,
            addressRegion: addressRegion,
            postalCode: postalCode,
            addressCountry: 'GB',
        },
        openingHoursSpecification: [
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                ],
                opens: '09:00',
                closes: '17:00',
            },
        ],
    }

    return (
        <Seo
            title="361 People - Executive Coach, Business Coach and HR Specialist"
            description="Executive Coach, Business Coach and and HR Specialist, based in Cornwall, South West England and beyond."
            pathname={location?.pathname}
        >
            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
            </script>
        </Seo>
    )
}
