// extracted by mini-css-extract-plugin
export var divider = "header-module--divider--79ad2 bg-white h-ms-10 m-ms2";
export var link = "header-module--link--bfd5d transition ease-in-out cursor-pointer h-full flex items-center ml-ms3 text-lg text-stone-800 hover_text-secondary";
export var linksWrapper = "header-module--linksWrapper--3543b hidden xl_flex items-center";
export var logoWrapper = "header-module--logoWrapper--a1b94 flex items-center";
export var menuIcon = "header-module--menuIcon--e4f74 block xl_hidden w-ms2 before_mb-ms-4 after_mt-ms-4";
export var mobileHomeLink = "header-module--mobileHomeLink--4cba9 header-module--mobileLink--79ff7 text-lg text-white text-center py-ms-3 xl_hidden";
export var mobileLink = "header-module--mobileLink--79ff7 text-lg text-white text-center py-ms-3";
export var mobileLinksWrapper = "header-module--mobileLinksWrapper--cddee flex flex-col items-center";
export var mobileMenu = "header-module--mobileMenu--89464 bg-gray-900 bg-opacity-95 invisible transition-all opacity-0 duration-300 ease-in-out z-40 fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center";
export var mobileMenuInner = "header-module--mobileMenuInner--29fdd max-h-[80vh] overflow-y-scroll";
export var navBar = "header-module--navBar--2c114 w-full flex justify-between items-center py-ms2";
export var navContainer = "header-module--navContainer--e1ab0 container mx-auto relative z-50";
export var open = "header-module--open--6afc5";