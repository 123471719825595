import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './contact.module.css'
import { StaticImage } from 'gatsby-plugin-image'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'

const Contact = ({ parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const { email, phone, linkedIn } = useSiteMetadata()
    return (
        <>
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <div className={classes.imageWrapper}>
                        <StaticImage
                            src="../../images/Charlie-V-153.jpg"
                            className={classes.image}
                        />
                    </div>
                    <div className={classes.boxWrapper}>
                        <div className={classes.contactBoxes}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                className={classes.socialIcon}
                                viewBox="0 0 28 28"
                            >
                                <path d="M22.4382 27.9999C21.2182 27.9999 19.5044 27.5587 16.9382 26.1249C13.8176 24.3749 11.4038 22.7593 8.30007 19.6637C5.30757 16.673 3.85132 14.7368 1.8132 11.028C-0.489302 6.84053 -0.0968016 4.64553 0.341948 3.70741C0.864448 2.58616 1.6357 1.91553 2.63257 1.24991C3.19879 0.878931 3.79799 0.560919 4.42257 0.299908C4.48507 0.273033 4.5432 0.247408 4.59507 0.224283C4.90445 0.0849084 5.3732 -0.125717 5.96695 0.0992834C6.3632 0.248033 6.71695 0.552408 7.2707 1.09928C8.40632 2.21928 9.9582 4.71366 10.5307 5.93866C10.9151 6.76428 11.1694 7.30928 11.1701 7.92053C11.1701 8.63616 10.8101 9.18803 10.3732 9.78366C10.2913 9.89553 10.2101 10.0024 10.1313 10.1062C9.6557 10.7312 9.55132 10.9118 9.62007 11.2343C9.75945 11.8824 10.7988 13.8118 12.5069 15.5162C14.2151 17.2205 16.0888 18.1943 16.7394 18.333C17.0757 18.4049 17.2601 18.2962 17.9051 17.8037C17.9976 17.733 18.0926 17.6599 18.1919 17.5868C18.8582 17.0912 19.3844 16.7405 20.0832 16.7405H20.0869C20.6951 16.7405 21.2157 17.0043 22.0782 17.4393C23.2032 18.0068 25.7726 19.5387 26.8994 20.6755C27.4476 21.228 27.7532 21.5805 27.9026 21.9762C28.1276 22.5718 27.9157 23.0387 27.7776 23.3512C27.7544 23.403 27.7288 23.4599 27.7019 23.523C27.4389 24.1465 27.119 24.7444 26.7463 25.3093C26.0819 26.303 25.4088 27.0724 24.2851 27.5955C23.708 27.8685 23.0765 28.0067 22.4382 27.9999Z" />
                            </svg>
                            <p>
                                Call me on{' '}
                                <a
                                    href={'tel:' + phone}
                                    className={classes.link}
                                >
                                    {phone}
                                </a>{' '}
                                to discuss how I can help you achieve your
                                business goals
                            </p>
                        </div>
                        <div className={classes.contactBoxes}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                className={classes.socialIcon}
                                viewBox="0 0 32 32"
                            >
                                <path d="M26.5 5H5.5C4.57205 5.00099 3.68238 5.37006 3.02622 6.02622C2.37006 6.68238 2.00099 7.57205 2 8.5V23.5C2.00099 24.428 2.37006 25.3176 3.02622 25.9738C3.68238 26.6299 4.57205 26.999 5.5 27H26.5C27.428 26.999 28.3176 26.6299 28.9738 25.9738C29.6299 25.3176 29.999 24.428 30 23.5V8.5C29.999 7.57205 29.6299 6.68238 28.9738 6.02622C28.3176 5.37006 27.428 5.00099 26.5 5ZM25.6138 10.7894L16.6138 17.7894C16.4382 17.9258 16.2223 17.9999 16 17.9999C15.7777 17.9999 15.5618 17.9258 15.3862 17.7894L6.38625 10.7894C6.28051 10.7095 6.19169 10.6094 6.12496 10.495C6.05823 10.3805 6.01491 10.2539 5.99753 10.1225C5.98015 9.99118 5.98905 9.85767 6.02371 9.72978C6.05837 9.60189 6.1181 9.48216 6.19944 9.37755C6.28077 9.27294 6.38208 9.18554 6.49749 9.12043C6.61289 9.05532 6.74008 9.01379 6.87168 8.99825C7.00327 8.98272 7.13664 8.9935 7.26403 9.02996C7.39142 9.06642 7.5103 9.12783 7.61375 9.21062L16 15.7331L24.3862 9.21062C24.596 9.05226 24.8596 8.98281 25.1201 9.01728C25.3806 9.05175 25.617 9.18738 25.7783 9.39483C25.9396 9.60228 26.0128 9.86488 25.982 10.1259C25.9512 10.3868 25.8189 10.6252 25.6138 10.7894Z" />
                            </svg>
                            <p>
                                Email me:{' '}
                                <a
                                    href={'mailto:' + email}
                                    className={classes.link}
                                >
                                    {email}
                                </a>
                                .
                            </p>
                        </div>
                        <div className={classes.contactBoxes}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                className={classes.socialIcon}
                                viewBox="0 0 32 32"
                            >
                                <path d="M16 14C17.1046 14 18 13.1046 18 12C18 10.8954 17.1046 10 16 10C14.8954 10 14 10.8954 14 12C14 13.1046 14.8954 14 16 14Z" />
                                <path d="M16 2C10.4862 2 6 6.29063 6 11.5625C6 14.0731 7.14437 17.4119 9.40125 21.4862C11.2137 24.7575 13.3106 27.7156 14.4012 29.1875C14.5855 29.439 14.8265 29.6435 15.1046 29.7845C15.3827 29.9255 15.6901 29.999 16.0019 29.999C16.3137 29.999 16.6211 29.9255 16.8992 29.7845C17.1772 29.6435 17.4182 29.439 17.6025 29.1875C18.6913 27.7156 20.79 24.7575 22.6025 21.4862C24.8556 17.4131 26 14.0744 26 11.5625C26 6.29063 21.5138 2 16 2ZM16 16C15.2089 16 14.4355 15.7654 13.7777 15.3259C13.1199 14.8864 12.6072 14.2616 12.3045 13.5307C12.0017 12.7998 11.9225 11.9956 12.0769 11.2196C12.2312 10.4437 12.6122 9.73098 13.1716 9.17157C13.731 8.61216 14.4437 8.2312 15.2196 8.07686C15.9956 7.92252 16.7998 8.00173 17.5307 8.30448C18.2616 8.60723 18.8864 9.11992 19.3259 9.77772C19.7654 10.4355 20 11.2089 20 12C19.9988 13.0605 19.577 14.0773 18.8271 14.8271C18.0773 15.577 17.0605 15.9988 16 16Z" />
                            </svg>
                            <p>
                                I live and work around Wadebridge, Cornwall but
                                do have many clients further afield so don't
                                hesitate to contact me during normal office
                                hours
                            </p>
                        </div>
                        <div className={classes.contactBoxes}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className={classes.socialIcon}
                            >
                                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                <rect x="2" y="9" width="4" height="12"></rect>
                                <circle cx="4" cy="4" r="2"></circle>
                            </svg>
                            <p>
                                Follow me on{' '}
                                <a
                                    href={linkedIn}
                                    className={classes.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    LinkedIn
                                </a>{' '}
                                to get all my business updates covering
                                Executive Coaching and HR
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
