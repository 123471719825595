import React from 'react'
import { Seo } from '../components/seo'
import FwImgTitleText from '../components/fwImgTitleText'
import PageTitle from '../components/pageTitle'
import Spacer from '../components/spacer'
import ListTwo from '../components/listTwo'
import Button from '../components/button/button'
import FiftyFiftySlider from '../components/fiftyFiftySlider'
import Cta from '../components/cta'

const CoachingPage = () => {
    const listTwoData = [
        {
            title: 'Imposter syndrome',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Anxiety',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Stress/Burnout',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Overcoming fear-based thinking to reach full potential ',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Change in role  ',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Business challenges ',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Improving leadership skills ',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
        {
            title: 'Strategic decision making ',
            text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        },
    ]

    const pilarsData = [
        {
            text: 'As your coach, I will be your critical friend, asking you insightful questions that you might not ask yourself.',
        },
        {
            text: 'I will work alongside you, being your advocate and confidant, whilst offering an honest, confidential perspective.',
        },
        {
            text: 'I am commercially-minded - this grounding will ensure a positive ROI for you and your business.',
        },
        {
            text: 'Our sessions will be led by you, with you, as client, bringing the topics for discussion.  I abide by strict confidentiality principles and , during the course of our sessions, ensure you have the time and space to clarify your intentions, reflect on your values, explore opportunities and achieve your goals.',
        },
        {
            text: "My sessions are bespoke, to reflect my clients' needs and objectives - I do not believe that offering bulk-buy packages meet the needs of my clients and am available free of charge between sessions by phone, text or email to handle any burning matters or questions.",
        },
    ]

    return (
        <>
            <PageTitle
                title="Executive Coaching"
                text=" I am a certified Coach, working with executives, leaders and individuals in a variety of industries, both in person and virtually."
            />
            <Spacer />
            <FwImgTitleText
                image="exec"
                title="As your coach, I will be your critical friend"
                text="Asking you insightful questions that you might not ask yourself.  I will work alongside you, being your advocate and confidant, whilst offering an honest, confidential perspective and delivering a positive ROI."
            />
            <Cta text="Why not contact me for a complimentary 30 minute 'virtual coffee' session to understand your needs and how I can work with you to achieve your goals.">
                <Button
                    url="/contact"
                    text="book a free consultation"
                    variation="primary"
                />
            </Cta>
            <FiftyFiftySlider
                image="exec"
                title="as your Coach, I will..."
                sliderData={pilarsData}
            />
            <Spacer />
            <ListTwo
                title="Executive Coaching Services"
                text="Here are some of the common reasons for clients to undertake my coaching services"
                listTwoData={listTwoData}
            />
            <Cta text="Charlie was very skilled in questioning in order to promote discussion and prompt other lines of thinking. She did not presume any one answer was correct and did not interject with her personal opinions. Discussions were guided using her professional expertise and thought-provoking questions." />
            <div className="text-center px-ms1">
                <div className="mb-ms6 text-base md_text-lg text-stone-600">
                    Contact me for a free informal discussion about how I can
                    add value to your organisation and your people.
                </div>
                <Button
                    url="/contact"
                    text="Book a free consultation"
                    variation="primary"
                />
            </div>
        </>
    )
}

export default CoachingPage

export const Head = ({ location }) => {
    return (
        <Seo
            title="Executive Coaching"
            description="I am a certified Executive Coach, who works with executives, leaders and individuals in a variety of industries, both in person and virtually."
            pathname={location?.pathname}
        />
    )
}
