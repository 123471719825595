// extracted by mini-css-extract-plugin
export var container = "listOne-module--container--49798 w-full";
export var contentWrapper = "listOne-module--contentWrapper--49544 flex flex-col justify-center h-full";
export var divider = "listOne-module--divider--03034 w-ms8 h-ms-6 bg-secondary mt-ms4";
export var listItem = "listOne-module--listItem--0051e bg-white shadow rounded p-ms4";
export var listItemText = "listOne-module--listItemText--15f16 text-lg lg_text-xl text-stone-600 mt-ms1";
export var listWrapper = "listOne-module--listWrapper--41d57 grid grid-cols-1 grid-rows-3 gap-ms4";
export var svg = "listOne-module--svg--2ba13 fill-secondary stroke-none";
export var text = "listOne-module--text--cd1af text-base md_text-lg text-stone-600 mt-ms1";
export var title = "listOne-module--title--d2dcc text-stone-700";
export var wrapper = "listOne-module--wrapper--74600 container mx-auto grid gap-ms5 xl_gap-ms12 grid-flow-row auto-rows-max xl_grid-cols-2";