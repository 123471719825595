// extracted by mini-css-extract-plugin
export var container = "testimonialSlider-module--container--020e7 bg-tertiary py-ms8";
export var details = "testimonialSlider-module--details--10455 mt-ms4 mb-ms6 text-center sm_text-left";
export var detailsWrapper = "testimonialSlider-module--detailsWrapper--1c392 w-full flex justify-center md_justify-end";
export var divider = "testimonialSlider-module--divider--3da47 w-ms8 h-ms-6 bg-white";
export var divider1 = "testimonialSlider-module--divider1--ea2e3 testimonialSlider-module--divider--3da47 w-ms8 h-ms-6 bg-white mt-ms4 mx-auto";
export var divider2 = "testimonialSlider-module--divider2--e4fd8 testimonialSlider-module--divider--3da47 w-ms8 h-ms-6 bg-white mx-auto sm_mx-0 mt-ms4";
export var industy = "testimonialSlider-module--industy--239ee text-base md_text-lg text-stone-600";
export var name = "testimonialSlider-module--name--2fb21 font-serif text-stone-700 text-2xl";
export var slideContainer = "testimonialSlider-module--slideContainer--4662a relative w-10/12 mx-auto";
export var sliderWrapper = "testimonialSlider-module--sliderWrapper--012b8 w-full overflow-hidden";
export var testimonial = "testimonialSlider-module--testimonial--ced88 text-base md_text-lg 2xl_text-xl text-stone-700 text-center italic";
export var testimonialWrapper = "testimonialSlider-module--testimonialWrapper--8009e";
export var title = "testimonialSlider-module--title--dabb3 text-secondary";
export var titleWrapper = "testimonialSlider-module--titleWrapper--6261d mb-ms4 text-center";
export var wrapper = "testimonialSlider-module--wrapper--c2118 mx-auto container";