import React, { createContext, useState } from 'react'

export const LayoutContext = createContext()

const LayoutProvider = ({ children }) => {
    const [layout, setLayout] = useState({
        footer: true,
    })

    return (
        <LayoutContext.Provider value={{ layout, setLayout }}>
            {children}
        </LayoutContext.Provider>
    )
}

export default LayoutProvider
