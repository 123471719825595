import React from 'react'
import { Seo } from '../components/seo'
import Contact from '../components/contact'
import PageTitle from '../components/pageTitle'
import Spacer from '../components/spacer'

const ContactPage = () => {
    return (
        <>
            <PageTitle
                title="Contact Me"
                text="Reach out to me for a free, no-obligation informal chat to find out more about how I can add value to you and your business."
            />
            <Spacer />
            <Contact />
        </>
    )
}

export default ContactPage

export const Head = ({ location }) => {
    return (
        <Seo
            title="Contact me"
            description="Contact page innit"
            pathname={location?.pathname}
        />
    )
}
