import React from 'react'
import { Link } from 'gatsby'

const Button = ({ url = '/', text = 'Hello', variation = 'primary' }) => {
    function buttonStyle() {
        if (variation === 'primary') {
            return 'btn-primary'
        } else if (variation === 'secondary') {
            return 'btn-secondary'
        } else if (variation === 'tertiary') {
            return 'btn-tertiary'
        } else {
            return 'btn-primary'
        }
    }

    return (
        <Link className={buttonStyle()} to={url} title={text}>
            {text}
        </Link>
    )
}

export default Button
