import React from 'react'
import { Seo } from '../components/seo'
import Button from '../components/button/button'
import TextImgText from '../components/textImgText'
import FwImgTitleText from '../components/fwImgTitleText'
import HeroOne from '../components/heroOne'
import TestimonialSlider from '../components/testimonialSlider'
import Spacer from '../components/spacer'
import Cta from '../components/cta'

const AboutPage = () => {
    return (
        <>
            <HeroOne
                image="about"
                title="Executive Coach and HR Specialist"
                text="I am a qualified Executive Coach and HR Specialist. Based in Cornwall, I work face-to-face with clients across the South West, or virtually in the UK and beyond."
            />
            <Cta text="The services I offer have transformational effects, and support leaders and businesses in achieving their goals.">
                <Button
                    url="/contact"
                    text="Book a free consultation"
                    variation="primary"
                />
            </Cta>
            <FwImgTitleText
                image="about"
                title="I am passionate about what I do"
                text="Listening, supporting, advocating, challenging and making a difference. My commercial awareness ensures a cost-benefit for my clients."
            />
            <Spacer />
            <TextImgText
                image="about"
                title="Why me."
                text="<p>My background spans a variety of industries, from Motorsport and Media, to Financial and Professional Services. Regardless of industry, I believe that an organisation's people lie at the heart of success. I am professional, yet friendly, with strong integrity.</p>
                <p>I firmly believe that coaching can have transformative effects and I pride myself on 'making a difference'.</p>
                <p>I possess qualifications in Executive Coaching & Mentoring, accredited by the Institute of Leadership & Management (ILM), and am also affiliated by the European Mentoring & Coaching Council (EMCC). From an HR perspective, I possess a Masters degree in Human Resources, awarded with Distinction. I'm not only great on paper, I am proud to be commercially-minded, pragmatic and client-focused.</p>
                <p>My clients value my insight and robust ROI that my services and expertise offer.</p>"
                title2="Who I work with"
                text2="<p>I work alongside leaders, executives and individuals. This can be face-to-face in Cornwall or virtually anywhere else. I have a broad client-base from a wide range of industries.</p>"
            />
            <Spacer />
            <TestimonialSlider />
        </>
    )
}

export default AboutPage

export const Head = ({ location }) => {
    return (
        <Seo
            title="Executive Coach and HR Specialist"
            description="I am a qualified Executive Coach and HR Specialist. Based in Cornwall, I work face-to-face with clients across the South West, or virtually in the UK and beyond."
            pathname={location?.pathname}
        />
    )
}
