import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './pageTitle.module.css'

const PageTitle = ({
    title = 'Hello',
    text = 'How are you?',
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `hOne ${titleClass}`

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <h1 className={titleClassCombined}>{title}</h1>
                <p className={classes.text}>{text}</p>
                <div className={classes.divider} />
            </div>
        </div>
    )
}

export default PageTitle
