import React, { useState, useEffect } from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './scrollTop.module.css'

const ScrollTop = ({ showBelow }) => {
    const classes = useStyle(defaultClasses)
    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    return (
        <>
            {show && (
                <div className={classes.container}>
                    <button
                        onClick={handleClick}
                        className={classes.button}
                        aria-label="to top"
                        aria-hidden="true"
                    >
                        Top
                    </button>
                </div>
            )}
        </>
    )
}
export default ScrollTop
