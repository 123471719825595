import React from 'react'
import { Link } from 'gatsby'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './logo.module.css'

const Logo = ({ shade, parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const shadeMe = () => {
        if (shade === 'light') {
            return classes.logoLight
        } else {
            return classes.logoDark
        }
    }

    return (
        <Link to="/">
            <h3 className={shadeMe()}>361 People</h3>
        </Link>
    )
}

export default Logo
