import * as React from 'react'
import { useStyle } from '../../hooks/classify'
import { StaticImage } from 'gatsby-plugin-image'
import * as defaultClasses from './textImgText.module.css'
const TextImgText = ({
    image = 'default',
    title = 'Welcome to my website',
    text = 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    title2 = 'Welcome to my website again',
    text2 = 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    function imageSelector() {
        if (image === 'default') {
            return (
                <StaticImage
                    src="../../images/placeholder.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        } else if (image === 'index') {
            return (
                <StaticImage
                    src="../../images/desk.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        } else if (image === 'about') {
            return (
                <StaticImage
                    src="../../images/Charlie-V-154.jpg"
                    alt={title}
                    className={classes.image}
                />
            )
        }
    }

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `formalTitle ${titleClass}`

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <h3 className={titleClassCombined}>{title}</h3>
                <div className={classes.contentWrapper}>
                    <div className="hidden sm_block">
                        <div className={classes.divider} />
                    </div>
                    <div
                        className={classes.text}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </div>
            </div>
            {imageSelector()}
            <div className={classes.wrapper}>
                <h3 className={titleClassCombined}>{title2}</h3>
                <div className={classes.contentWrapper}>
                    <div className="hidden sm_block">
                        <div className={classes.divider} />
                    </div>
                    <div
                        className={classes.text}
                        dangerouslySetInnerHTML={{ __html: text2 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default TextImgText
