import React from 'react'
import { useStyle } from '../../hooks/classify'
import { StaticImage } from 'gatsby-plugin-image'
import * as defaultClasses from './fiftyFiftySlider.module.css'
import Slider from '../slider'

const FiftyFliftySlider = ({
    image = 'default',
    title = 'Hello',
    sliderData,
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const slides = sliderData.map((slide, index) => {
        return (
            <div className={classes.slideContainer} key={index}>
                <div className={classes.slideWrapper}>{slide?.text}</div>
            </div>
        )
    })

    function imageSelector() {
        if (image === 'default') {
            return (
                <StaticImage
                    src="../../images/placeholder.jpg"
                    className={classes.image}
                />
            )
        } else if (image === 'exec') {
            return (
                <StaticImage
                    src="../../images/Charlie-V-151.jpg"
                    className={classes.image}
                />
            )
        }
    }

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `formalTitle ${titleClass}`

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <div className={classes.layout}>
                    <div className={classes.imageWrapper}>
                        {imageSelector()}
                    </div>
                    <div className={classes.sliderWrapper}>
                        <h3 className={titleClassCombined}>{title}</h3>
                        <Slider
                            effect="fade"
                            loop={true}
                            navigation={false}
                            pagination={true}
                            autoplay={7000}
                            spaceBetween={30}
                        >
                            {slides}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FiftyFliftySlider
