import * as React from 'react'
import Header from '../header'
import Footer from '../footer'

const Layout = ({ children, location }) => {
    return (
        <>
            <Header location={location?.pathname} />
            {children}
            <Footer location={location?.pathname} />
        </>
    )
}

export default Layout
